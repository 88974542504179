// import { USER_PROFILE } from '../../constant/storageKey';
// import { UserProfile } from '../../types/user';

type FormatWalletAddressPrarams = {
  walletAddress: string;
  replaceStr?: string;
  startCount?: number;
  endCount?: number;
};

export function formatWalletAddress({
  walletAddress,
  replaceStr = '...',
  startCount = 4,
  endCount = 4,
}: FormatWalletAddressPrarams) {
  if (walletAddress.length <= startCount + endCount) {
    return walletAddress;
  }
  return `${walletAddress.slice(0, startCount)}${replaceStr}${walletAddress.slice(-endCount)}`;
}

export function walletAddress2UserId(walletAddress: string) {
  const id = walletAddress.replace(/^0x/, '');
  return `@sdn_${id}:${id}`;
}

export function formatNumber(target: number) {
  if (target < 1000) {
    return target;
  }
  return `${Math.floor(target / 1000)}.${Math.floor((target % 1000) / 10)}K`;
}

export function sign2Token(sign: string) {
  return Buffer.from(sign.substring(2), 'hex')
    .toString('base64')
    .replace(/(\+)/g, '-') // Convert '+' to '-'
    .replace(/\//g, '_'); // Convert '/' to '_'
}

export function getDefaultAvatar(id: string, size = 80) {
  return `https://static.sending.me/beam/${size}/${id}?colors=FC774B,FFB197,B27AFF,DAC2FB,F0E7FD&square=true`;
}

export function getSdmAccessToken() {
  return localStorage.getItem('access_token');
  // try {
  //   const { access_token }: UserProfile =
  //     JSON.parse(localStorage.getItem(USER_PROFILE) || '') || {};
  //   return access_token || '';
  // } catch {
  //   return '';
  // }
}

export function getSdnAccessToken() {
  return localStorage.getItem('mx_access_token');
  // try {
  //   const { access_token }: UserProfile =
  //     JSON.parse(localStorage.getItem(USER_PROFILE) || '') || {};
  //   return access_token || '';
  // } catch {
  //   return '';
  // }
}

const LOWERCASE = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGITS = '0123456789';

function randomStringFrom(len: number, chars: string): string {
  let ret = '';

  for (let i = 0; i < len; ++i) {
    ret += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return ret;
}

export function randomString(len: number): string {
  return randomStringFrom(len, UPPERCASE + LOWERCASE + DIGITS);
}

export function generateClientSecret(): string {
  return randomString(32);
}
