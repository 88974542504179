import { Dispatch, createContext, useContext } from 'react';
import { UserProfile } from '../types/user';
import { USER_PROFILE } from '../constant/storageKey';

export const UserProfileContext = createContext<UserProfile | null>(null);
export const UserProfileDispatchContext = createContext<Dispatch<UserReducerAction> | null>(null);

export function useUserProfile() {
  return useContext(UserProfileContext);
}

export function useUserProfileDispath() {
  return useContext(UserProfileDispatchContext);
}

export enum UserProfileActionType {
  Update = 'UserProfile:Update',
  Logout = 'UserProfile:Logout',
}

export interface UserReducerAction {
  type: UserProfileActionType;
  payload: Partial<UserProfile>;
}

export const initialState = {
  avatar_url: '',
  displayname: '',
  extended_fields: {},
  threepids: [],
  wallet_address: '',
  nft_avatar: '',
  signature: '',
};

export const userReducer = (userProfile: UserProfile, action: UserReducerAction): UserProfile => {
  const _userProfile = { ...userProfile, ...action.payload };
  switch (action.type) {
    case UserProfileActionType.Update:
      localStorage.setItem(USER_PROFILE, JSON.stringify(_userProfile));
      return _userProfile;
    case UserProfileActionType.Logout:
      localStorage.setItem(USER_PROFILE, JSON.stringify(initialState));
      return initialState;
    default:
      return initialState;
  }
};
