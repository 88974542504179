import { createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

const routes = createBrowserRouter([
  {
    path: '/',
    index: true,
    Component: lazy(() => import('../pages/home/Home')),
  },
  {
    path: '/authorize',
    index: true,
    Component: lazy(() => import('../pages/authorize/Authorize')),
  },
]);

export default routes;
