import { ReactNode, useReducer, memo } from 'react';
import { WalletProps, WalletContext, WalletDispatchContext } from '../contexts/WalletContext';
import { walletReducer } from '../reducers/walletReducer';

interface IProps {
  children: ReactNode;
}

const WalletProvider = ({ children }: IProps) => {
  const [wallet, dispatch] = useReducer(walletReducer, {
    provider: window.ethereum,
  } as WalletProps);

  return (
    <WalletContext.Provider value={wallet}>
      <WalletDispatchContext.Provider value={dispatch}>{children}</WalletDispatchContext.Provider>
    </WalletContext.Provider>
  );
};

export default memo(WalletProvider);
