import { WalletProps } from '../contexts/WalletContext';

export type WalletReducerAction = {
  type: string;
  payload: WalletProps;
};

export const walletReducer = (
  walletProps: WalletProps,
  action: WalletReducerAction
): WalletProps => {
  switch (action.type) {
    case 'connected':
      return { ...walletProps, ...action.payload };
    case 'disConnected':
      return { ...walletProps, ...action.payload };
    default:
      return {};
  }
};
